/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@media print {

    ion-header, ion-backdrop, .tabbar, ion-footer, ion-content::part(background) {
      display: none !important;
    }
    ion-nav {
      contain: none;
      overflow: visible;
    }
    ion-split-pane {
      display: block;
    }
    .scroll-content,
    ion-modal,
    ion-modal .modal-wrapper,
    ion-modal .ion-page,
    ion-modal .ion-page > ion-content,
    .ion-page,
    .ion-page > ion-content,
    ion-split-pane,
    ion-tab,
    ion-tabs,
    ion-router-outlet,
    .app-root,
    ion-content::part(scroll),
    body {
      contain: none;
      position: initial;
      height: auto;
      overflow: visible;
    }
    .fixed-content, .scroll-content {
      margin-top: 0 !important;
    }
  }





  
.ion-page {
min-width: 320px;
max-width: 3000px; 
}




ion-header {
font-size: 10pt; 
}




ion-content { 
/*
PRINCIPLE: 
 /--offset-bottom: auto!important;
  --overflow: hidden;

 ------- ADD TO EACH HIDE BAR PAGE / WITHOUT A SCROLL CHILD

 ion-content mode="md"  FIXES content list bounce
*/

//ALLOW CONTENT SCROLL GLOBAL
overflow: auto;

vertical-align: top; 
font-size: 11pt; 
}

 
//========== HIDES ALL SCROLL BARS

@media only screen and(max-width: 1099px) {
/* chrome, safari */
ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
  
}
/* firefox */
ion-content::part(scroll) {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none;
  
}


}




  .custom-modal-class{
    --backdrop-opacity: 0.8 !important;
  
    .modal-wrapper {
      --width: 300px !important;
      --height: 350px !important;
      --border: 2px solid gray !important;
      --border-radius: 5px !important;
      position: absolute;
      top: 10%;
    }


    .popover-wide .alert-wrapper {
      width: 98vw;
      height: fit-content;
    }

  }